.confirm-container
  width: 770px
  margin: auto
  margin-top: 200px
  +media-query(desktop)
    max-width: 770px
    width: 100%

.confirm-title
  margin: 0 auto 30px auto

.confirm-table
  margin-bottom: 20px

  &-row
    display: flex
    flex-direction: row
    width: 100%
    background: #ffffff
    color: #1b214a
    font-size: 16px
    line-height: 26px
    letter-spacing: 0.32px
    border-top: 1px solid #e9eaef
    +media-query(tablet)
      font-size: 14px

    &:first-child
      border-top: none

    &:nth-child(even)
      background: #fdfdfe

  &-cell
    width: calc(100% - 222px)
    box-sizing: border-box
    padding: 12px 30px 11px 30px
    +media-query(tablet)
      width: 55%
      padding-right: 15px
      padding-left: 15px
      flex-grow: 2

    &:first-child
      width: 222px
      border-right: 1px solid #e9eaef
      +media-query(tablet)
        width: 45%

    &__total
      font-size: 18px
      padding-top: 15px
      padding-bottom: 15px
      +media-query(tablet)
        font-size: 16px
        padding-top: 10px
        padding-bottom: 10px

.get-ticket-wrap
  margin: 0 auto
  padding: 13px 0 14px 0

  p
    color: #848484
    font-size: 18px
    line-height: 29px
    letter-spacing: 0.36px
    +media-query(tablet)
      font-size: 16px
      line-height: 24px

.get-ticket-info
  font-size: 15px
  letter-spacing: 0.15px
  line-height: 22px
  color: #848484

  svg
    vertical-align: top
    margin: 0 9px 0 0
    transform: translateY(-3px)

.get-ticket-buttons
  display: flex
  justify-content: space-between
  margin: 0 0 33px 0
  font-size: 0
  line-height: 0
  padding: 37px 0 0 0
  +media-query(tablet)
    flex-direction: column
    justify-content: flex-start

.confirm-button
  +media-query(tablet)
    margin-bottom: 8px

  span
    font-size: 19px
    line-height: 26px
    font-weight: 500
    +media-query(wide)
      padding: 0 24px 0 24px


.confirm-box-contacts
  padding: 32px 40px 29px 40px
  margin: 0 auto 69px auto
  width: 770px
  border-radius: 6px
  background: #fcfcfd
  opacity: 1
  box-shadow: 0px 3px 20px rgb(0 0 0 / 3%)
  box-sizing: border-box
  +media-query(tablet)
    padding: 22px 20px 19px 20px

  h3
    letter-spacing: 0.52px
    margin: 0 0 8px 0

  p
    font-size: 15px
    line-height: 26px
    letter-spacing: 0.15px
    display: block
    padding-left: 2px
    margin: 0 0 26px 0

.confirm-contact-info
  display: flex
  flex-direction: row

  &--image
    max-width: 46px
    min-width: 46px
    margin-right: 34px

    img
      display: block
      width: 100%
      height: auto

  &--content
    padding: 10px 0 0 0

    h5
      letter-spacing: 0.4px
      margin: 0 0 17px 0

    ul
      padding: 0 0 0 2px
      margin: 0

      li
        list-style: none
        margin: 0
        padding: 0
        color: #1b214a
        line-height: 26px

        span
          color: #a5a5a5

        i
          display: inline-block
          vertical-align: top

        a
          color: #1b214a

          span
            color: #a5a5a5

  &--tel
    font-size: 19px
    letter-spacing: -0.38px
    margin: 0 0 9px 0

    svg
      margin: 0 8px 0 0
      transform: translateY(1px)

  &--email
    font-size: 18px
    letter-spacing: 0

    svg
      margin: 0 9px 0 0
      transform: translateY(3px)

