
// Alignment
.text-left
  text-align: left

.text-right
  text-align: right

.text-center
  text-align: center


// Contextual colors