#main-header
  background: #ffffff
  position: fixed
  z-index: 999
  min-height: 137px
  align-items: center
  display: flex
  top: 0
  left: 0
  width: 100%
  box-shadow: 0px 4px 10px 0px $border-color
  +media-query(tablet)
    min-height: 0

.header-wrap
  display: flex
  flex-direction: row
  justify-content: space-between
  font-size: 26px
  +media-query(desktop)
    flex-direction: row
    justify-content: space-between
  .main-logo
    width: 9em
    display: block
    +media-query(tablet)
      font-size: 20px
    +media-query(phone)
      font-size: 16px
    img
      display: block
      width: 100%
      height: auto

.header-logo
  margin: auto
  +media-query(desktop)
    width: auto
  +media-query(tablet)
    padding: 10px 0 5px 0

.header-contacts
  display: flex
  flex-direction: row
  width: 40%
  align-items: center
  +media-query(wide)
    flex-direction: column
    justify-content: center
    align-items: flex-start
  a
    line-height: 26px
    color: $brand-dark-blue
    i
      display: inline-block
      vertical-align: baseline
      margin-right: 9px
      transform: translateY(1)
  &__email
    padding-right: 21px
    font-size: 18px
    color: $brand-dark-blue
    +media-query(tablet)
      padding-top: 8px
    i
      width: 20px
      height: 15px
      transform: translateY(1px)
      background: url('../../../images/icons/icon-email.svg') no-repeat center 100%

  &__phone
    transform: translateY(-1px)
    font-size: 19px
    color: $brand-dark-blue
    +media-query(desktop)
      padding-top: 8px
    i
      width: 21px
      height: 21px
      transform: translateY(3px)
      margin-right: 8px
      background: url('../../../images/icons/icon-phone.svg') no-repeat center 100%
    span
      color: $brand-orange

.header-icon
  align-self: center
  width: 40%
  display: flex
  justify-content: flex-end
  a
    display: block
    font-size: 16px
    line-height: 26px
    color: $brand-dark-blue
    width: 4em
    height: 4em
    text-align: center
    +media-query(tablet)
      font-size: 12px
      +media-query(phone)
        font-size: 8px

.header__button
  font-size: 16px
  +media-query(tablet)
    font-size: 12px
    +media-query(phone)
      font-size: 8px

.svg-inline--fa.fa-w-14
  width: 3em
  height: 3em
  margin: 0.5em 0
  &:hover
    color: $brand-blue
    width: 3.5em
    height: 3.5em
    margin: 0
    transition: all 0.3s ease
.svg-inline--fa.fa-w-16
  width: 3em
  height: 3em
  margin: 0.5em 0
  &:hover
    color: $brand-blue
    width: 3.5em
    height: 3.5em
    margin: 0
    transition: all 0.3s ease

a.header-phone
  &_tablet
    display: none
    +media-query(tablet)
      display: block
  &_desktop
    display: block
    +media-query(tablet)
      display: none
