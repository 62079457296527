.accordeon
  &-wrapper
    background: #ffffff
    border: 1px solid $brand-gray-border
    box-shadow: 0 3px 16px rgb(0 0 0 / 4%)
    border-radius: 6px
    margin-bottom: 30px
  &-item
    &-wrap
      border-bottom: 1px solid $brand-gray-border
    &-title
      color: $brand-dark-blue
      font-size: 17px
      line-height: 20px
      font-weight: 700
      transition: color 0.3s
      position: relative
      padding: 21px 38px 21px 44px
      cursor: pointer
      -webkit-touch-callout: none
      -webkit-user-select: none
      -khtml-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      svg
        position: absolute
        left: 22px
        top: 26px
        transition: transform 0.3s
      &_active
        color: $brand-blue
        svg
          color: $brand-blue
          transform: rotate(90deg)


    &-content
      padding: 0 38px 0 47px
      display: none
      &_active
        display: block
      p
        color: $brand-gray-dark
        font-size: 16px
        line-height: 24px
        margin: 0 0 24px 0

