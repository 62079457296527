.custom-input
  outline: none
  font-family: 'Cabin', sans-serif
  box-sizing: border-box
  border: 1px solid $brand-gray-border
  border-radius: 4px
  background: #fff
  color: $brand-gray-dark
  font-size: 16px
  line-height: 26px
  width: 100%
  padding: 12px 16px 10px 16px
  letter-spacing: 0.32px
  &:disabled
    background: $brand-gray-xlight

  input::-moz-placeholder,
  textarea::-moz-placeholder,
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder
    opacity: 1

.input-error
  border-color: $brand-red